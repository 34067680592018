import { createContext, useContext, useState, useCallback } from "react";
import { message } from "antd";

// Services
import api from "../services/api";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  // State
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("@Smartraining-app:user")) || null
  );
  const [token, setToken] = useState(
    localStorage.getItem("@Smartraining-app:token") || null
  );
  const [loading, setLoading] = useState(false);

  const signIn = useCallback(async ({ email, password }) => {
    setLoading(true);

    try {
      const response = await api.post("sessions", { email, password });
      const { token, refresh_token, user } = response.data;

      localStorage.setItem("@Smartraining-app:refresh_token", refresh_token);
      localStorage.setItem("@Smartraining-app:token", token);
      localStorage.setItem("@Smartraining-app:user", JSON.stringify(user));

      setUser(user);
      setToken(token);
    } catch (err) {
      if (
        err?.response?.data?.message === "Incorrect email/password combination."
      ) {
        return message.error("Email/senha incorretos!");
      }
      return message.error("Ocorreu um erro, tente novamente mais tarde.");
    } finally {
      setLoading(false);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@Smartraining-app:token");
    localStorage.removeItem("@Smartraining-app:refresh_token");
    localStorage.removeItem("@Smartraining-app:user");

    setToken(null);
    setUser(null);
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, loading, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
