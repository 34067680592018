import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Services
import api from "../services/api";

// Components
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

// Assets
import ProfileIlustration from "../assets/images/account-infos-ilustration.svg";
import UsersIcon from "../assets/icons/users-icon.svg";
import CalendarIcon from "../assets/icons/calendar-icon.svg";
import ScaleIcon from "../assets/icons/scale-icon.svg";
import SwapIcon from "../assets/icons/swap-icon.svg";

// Form schema
const schema = yup
  .object({
    gender: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo sexo é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    birthdate: yup.string().required("Campo aniversário é obrigatório"),
    weigth: yup.string().required("Campo peso é obrigatório"),
    height: yup.string().required("Campo altura é obrigatório"),
  })
  .required();

export function Profile() {
  // State
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const onSubmit = async ({ gender, birthdate, height, weigth }) => {
    setIsLoading(true);
    try {
      await api.post("/clients", {
        email: queryParams.get("email"),
        step: queryParams.get("step"),
        gender,
        birthdate,
        height,
        weigth,
      });

      return history.replace(
        `/signup-goal?email=${queryParams.get("email")}&step=${
          Number(queryParams.get("step")) + 1
        }`
      );
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="signup-profile-step container">
      <div className="signup-profile-step__heading">
        <img
          className="heading__ilustration"
          src={ProfileIlustration}
          alt="Account infos ilustration"
        />
        <div className="heading__content">
          <h1 className="heading__title">Vamos completar o seu perfil</h1>
          <p className="heading__text">
            Isso nos ajudará a saber mais sobre você!
          </p>
        </div>
      </div>
      <form
        className="signup-profile-step__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Select
          placeholder="Selecione seu sexo"
          name="gender"
          leftElement={<img src={UsersIcon} alt="Users icon" />}
          required
          isErrored={!!errors.gender}
          errorMessage={errors.gender?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          <option value="female">Feminino</option>
          <option value="male">Masculino</option>
        </Input.Select>
        <Input
          placeholder="Data de nascimento"
          name="birthdate"
          type="date"
          leftElement={<img src={CalendarIcon} alt="Calendar icon" />}
          isErrored={!!errors.birthdate}
          errorMessage={errors.birthdate?.message}
          register={register}
        />
        <div className="form__input-box">
          <Input
            placeholder="Seu peso"
            name="weigth"
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            leftElement={<img src={ScaleIcon} alt="Scale icon" />}
            required
            isErrored={!!errors.weigth}
            errorMessage={errors.weigth?.message}
            register={register}
          />
          <div className="box">
            <span>KG</span>
          </div>
        </div>
        <div className="form__input-box">
          <Input
            placeholder="Sua altura"
            name="height"
            inputMode="numeric"
            pattern="[0-9]*"
            type="text"
            leftElement={<img src={SwapIcon} alt="Swap icon" />}
            required
            isErrored={!!errors.height}
            errorMessage={errors.height?.message}
            register={register}
          />
          <div className="box">
            <span>CM</span>
          </div>
        </div>
        <div className="form__submit-button">
          <Button type="submit" isLoading={isLoading} isFullWidth>
            Próximo
          </Button>
        </div>
      </form>
    </section>
  );
}
