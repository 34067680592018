import { Switch } from "react-router-dom";

import ScrollToTop from "../components/scrollToTop";
import Public from "./public";
import Private from "./private";

// Pages
import { Login } from "../pages/login";
import { Account } from "../pages/account";
import { Profile } from "../pages/profile";
import { Goal } from "../pages/goal";
import { Success } from "../pages/success";
import { Training } from "../pages/training";
import { FinishedTraining } from "../pages/finished-training";
import { SubmitTraining } from "../pages/submit-training";
import { Exercise } from "../pages/exercise";
import { Feed } from "../pages/feed";
import { Instructor } from "../pages/instructor";
import { Settings } from "../pages/settings";
import { EditAccount } from "../pages/settings/account";
import { EditProfile } from "../pages/settings/profile";
import { EditGoal } from "../pages/settings/goal";
import { EditPassword } from "../pages/settings/password";
import { Statistics } from "../pages/statistics";

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Public component={Login} isGuest path="/" exact />
        <Public component={Account} isGuest path="/signup" exact />
        <Public component={Profile} isGuest path="/signup-profile" exact />
        <Public component={Goal} isGuest path="/signup-goal" exact />
        <Public component={Success} isGuest path="/signup-success" exact />
        <Private component={Training} path="/training" exact />
        <Private component={Exercise} path="/exercise" exact />
        <Private component={FinishedTraining} path="/finished-training" exact />
        <Private component={SubmitTraining} path="/submit-training" exact />
        <Private component={Feed} path="/feed" exact />
        <Private component={Instructor} path="/instructor" exact />
        <Private component={EditAccount} path="/settings/account" exact />
        <Private component={EditProfile} path="/settings/profile" exact />
        <Private component={EditGoal} path="/settings/goal" exact />
        <Private component={EditPassword} path="/settings/password" exact />
        <Private component={Settings} path="/settings" exact />
        <Private component={Statistics} path="/statistics" exact />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
