import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery } from "react-query";
import { message } from "antd";

// Services
import api from "../../services/api";
import { queryClient } from "../../services/queryClient";

// Components
import { Header } from "../../components/ui/header";
import { Input } from "../../components/forms/Input";
import { Button } from "../../components/forms/Button";
import { Navigation } from "../../components/ui/navigation";

// Assets
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import ChartIcon from "../../assets/icons/chart-icon.svg";

// Form schema
const schema = yup
  .object({
    training_goal: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo meta é obrigatório",
        (field) => field !== "empty-disabled"
      ),
  })
  .required();

export function EditGoal({ location }) {
  const { user_id } = location.state;

  // State
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery(
    ["goals"],
    async () => {
      const { data: goals } = await api.get("/goals");
      const { data: user } = await api.get(`/clients/${user_id}`);

      const userGoals = {
        goals: goals,
        user_goal: user.goal.id,
      };

      setValue("training_goal", user.goal.id);

      return userGoals;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  // Hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      training_goal: data?.user_goal,
    },
  });
  const history = useHistory();

  const onSubmit = async ({ training_goal }) => {
    setIsLoading(true);
    try {
      await api.put(`/clients/${user_id}`, {
        goal_id: training_goal,
      });

      queryClient.invalidateQueries("goals");
      message.success("Dados alterados com sucesso!");

      history.goBack();
    } catch (error) {
      return message.error(
        "Ops, algo de errado aconteceu. Tente novamente mais tarde!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="signup-goal-step container">
      <Header
        icon={ArrowLeft}
        title="Seu objetivo"
        handleButtonClick={() => history.goBack()}
      />
      <form
        className="signup-goal-step__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Select
          placeholder="Escolha sua meta"
          name="training_goal"
          leftElement={<img src={ChartIcon} alt="Chart icon" />}
          required
          isErrored={!!errors.training_goal}
          errorMessage={errors.training_goal?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          {data?.goals?.map((goal) => (
            <option key={goal.id} value={goal.id}>
              {goal.name}
            </option>
          ))}
        </Input.Select>
        <div className="form__submit-button">
          <Button type="submit" isLoading={isLoading} isFullWidth>
            Editar
          </Button>
        </div>
      </form>
      <Navigation active="settings" />
    </section>
  );
}
