import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "react-query";

// Context
import AppProvider from "./context";

// Services
import { queryClient } from "./services/queryClient";

// Routes
import Routes from "./Routes";

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Router>
          <Routes />
        </Router>
      </AppProvider>
    </QueryClientProvider>
  );
}
