import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { message } from "antd";

// Services
import api from "../../services/api";

// Components
import { Header } from "../../components/ui/header";
import { Input } from "../../components/forms/Input";
import { Button } from "../../components/forms/Button";
import { Navigation } from "../../components/ui/navigation";

// Assets
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import PasswordIcon from "../../assets/icons/lock-icon.svg";

// Form schema
const schema = yup.object({
  current_password: yup.string().required("Campo senha atual é obrigatório"),
  password: yup.string().required("Campo nova senha é obrigatório"),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Campo nova senha e confirmação de senha precisam ser iguais"
    ),
});

export function EditPassword() {
  // State
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const onSubmit = async ({
    current_password,
    password,
    password_confirmation,
  }) => {
    setIsLoading(true);
    try {
      await api.patch(`/password/`, {
        current_password,
        new_password: password,
        password_confirmation,
      });

      message.success("Dados alterados com sucesso!");
      history.goBack();
    } catch (err) {
      if (
        err?.response?.data?.value.name ===
        "PasswordDoesntMatchWithCurrentPasswordError"
      ) {
        return message.error("Senha atual incorreta!");
      }
      return message.error(
        "Ops, algo de errado aconteceu. Tente novamente mais tarde!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="signup-goal-step container">
      <Header
        icon={ArrowLeft}
        title="Senha"
        handleButtonClick={() => history.goBack()}
      />
      <form
        className="signup-goal-step__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Password
          placeholder="Digite sua senha atual"
          name="current_password"
          leftElement={<img src={PasswordIcon} alt="User icon" />}
          required
          isErrored={!!errors.current_password}
          errorMessage={errors.current_password?.message}
          register={register}
        />
        <Input.Password
          placeholder="Digite sua nova senha"
          name="password"
          leftElement={<img src={PasswordIcon} alt="User icon" />}
          required
          isErrored={!!errors.password}
          errorMessage={errors.password?.message}
          register={register}
        />
        <Input.Password
          placeholder="Confirme sua nova senha"
          name="password_confirmation"
          leftElement={<img src={PasswordIcon} alt="User icon" />}
          required
          isErrored={!!errors.password_confirmation}
          errorMessage={errors.password_confirmation?.message}
          register={register}
        />
        <div className="form__submit-button">
          <Button type="submit" isLoading={isLoading} isFullWidth>
            Editar
          </Button>
        </div>
      </form>
      <Navigation active="settings" />
    </section>
  );
}
