import axios from "axios";

let isRefreshing = false;
let failedRequestQueue = [];

const api = axios.create({
  baseURL: "https://api-staging.smartraining.com.br/",
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@Smartraining-app:token");

    if (!token) return config;

    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      if (error.response.data?.message === "Invalid JWT token.") {
        const refresh_token = localStorage.getItem(
          "@Smartraining-app:refresh-token"
        );
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post("/sessions/refresh-token", { refresh_token })
            .then((response) => {
              const { token, refresh_token: refreshToken } = response.data;

              localStorage.setItem("@Smartraining-app:token", token);
              localStorage.setItem(
                "@Smartraining-app:refresh-token",
                refreshToken
              );
              api.defaults.headers["Authorization"] = `Bearer ${token}`;

              failedRequestQueue.forEach((request) => request.onSuccess(token));
              failedRequestQueue = [];
            })
            .catch((err) => {
              failedRequestQueue.forEach((request) => request.onFailure(err));
              failedRequestQueue = [];

              // TODO: SignOut
            })
            .finally(() => (isRefreshing = false));
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token) => {
              originalConfig.headers["Authorization"] = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err) => reject(err),
          });
        });
      } else {
        // TODO: SignOut
      }
    }

    return Promise.reject(error);
  }
);

export default api;
