import { Rate as AntdRate } from "antd";

export function Rate({ label, required = false, ...props }) {
  return (
    <div className="rate__wrapper">
      <label className="rate__label">
        {required && <span>* </span>}
        {label}
      </label>
      <AntdRate {...props} />
    </div>
  );
}
