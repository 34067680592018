import { Input as DefaultInput } from "./Input";
import { Password as PasswordInput } from "./Password";
import { Select as SelectInput } from "./Select";
import { Textarea as TextareaInput } from "./Textarea";

export function Input({ ...props }) {
  return <DefaultInput {...props} />;
}

Input.Password = PasswordInput;
Input.Select = SelectInput;
Input.Textarea = TextareaInput;
