// Assets
import CloseIcon from "../../assets/icons/close-icon.svg";

export const Header = ({
  title,
  icon = CloseIcon,
  handleButtonClick,
  className,
}) => (
  <header className={`${className || ""} custom-header__head`}>
    <div className="head__controls">
      <div className="head__button-container" onClick={handleButtonClick}>
        <img src={icon} alt="Close icon" />
      </div>
      {title && <h1 className="head__title">{title}</h1>}
    </div>
  </header>
);
