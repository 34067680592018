import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { random } from "lodash";

// Components
import { Button } from "../components/forms/Button";

// Assets
import FinishedTrainingIlustration from "../assets/images/finished-training-ilustration.svg";

export function FinishedTraining() {
  // State
  const [message, setMessage] = useState({ autor: "", texto: "" });
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    const response = axios.get(
      "https://pensador-api.vercel.app/?term=motivacao&max=100"
    );

    response.then((res) => {
      const position = random(99);
      const { autor, texto } = res.data.frases[position];
      setMessage({ autor, texto });
      setIsLoading(false);
    });
  }, []);

  return (
    <section className="finished-training container">
      <div className="finished-training__content">
        <div className="content__ilustration">
          <img
            className="ilustration__image"
            src={FinishedTrainingIlustration}
            alt="Finished Training Ilustration"
          />
        </div>
        <div className="content__message">
          <h1 className="message__title">
            Parabéns, você finalizou o seu treino de hoje!
          </h1>
          {!isLoading && (
            <p className="message__text">
              {`${message.texto} - ${message.autor}`}
            </p>
          )}
        </div>
      </div>
      <div className="success__button">
        <Button
          type="button"
          isFullWidth
          onClick={() => history.replace("/feed")}
        >
          Voltar para o início
        </Button>
      </div>
    </section>
  );
}
