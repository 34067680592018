import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import Avatar from "react-avatar";
import { Switch } from "antd";
import { differenceInYears } from "date-fns";

// Services
import api from "../../services/api";

// Context
import { useAuth } from "../../context/auth";

// Components
import { Header } from "../../components/ui/header";

// import { Button } from "../../components/forms/Button";
import { Navigation } from "../../components/ui/navigation";

// Assets
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import ChevronRight from "../../assets/icons/chevron-right-icon.svg";
import ProfileIcon from "../../assets/icons/settings/profile-icon.svg";
import AchievementIcon from "../../assets/icons/settings/achievement-icon.svg";
import ChartIcon from "../../assets/icons/settings/chart-icon.svg";
import PrivacyIcon from "../../assets/icons/settings/privacy-icon.svg";
import BellIcon from "../../assets/icons/settings/bell-icon.svg";

export function Settings() {
  // Hooks
  const { user: sessionUser } = useAuth();
  const history = useHistory();

  // Cached API call
  const { data } = useQuery(
    `user`,
    async () => {
      const { data } = await api.get(`/clients/${sessionUser.owner_id}`);

      const user = {
        name: `${data.first_name?.split(" ")[0]} ${data.last_name}`,
        height: data.height,
        weight: data.weight,
        age: differenceInYears(new Date(), new Date(data.birthdate)),
        goal: data?.goal?.name,
      };

      return user;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  return (
    <div className="settings container">
      <Header
        icon={ArrowLeft}
        title="Configuração"
        handleButtonClick={() => history.goBack()}
      />
      <div className="settings__profile">
        <div className="profile__wrapper">
          <Avatar name={data?.name} size="55" color="#dddada" round />
          <div>
            <p className="profile__title">{data?.name}</p>
            <p className="profile__subtitle">{data?.goal}</p>
          </div>
        </div>
        {/* TODO: Add upload avatar function */}
        {/* <Button>Editar</Button> */}
      </div>
      <div className="settings__profile-infos">
        <div className="profile-infos__card">
          <h2>{`${data?.height}cm`}</h2>
          <p>Altura</p>
        </div>
        <div className="profile-infos__card">
          <h2>{`${data?.weight}kg`}</h2>
          <p>Peso</p>
        </div>
        <div className="profile-infos__card">
          <h2>{data?.age}</h2>
          <p>Idade</p>
        </div>
      </div>
      <div className="settings__card">
        <h2>Conta</h2>
        <ul className="card__list">
          <li
            className="card__list-item"
            onClick={() =>
              history.push("/settings/account", {
                user_id: sessionUser.owner_id,
              })
            }
          >
            <div className="list-item__wrapper">
              <div className="list-item__title">
                <img src={ProfileIcon} alt="Profile icon" />
                <p>Informações da conta</p>
              </div>
              <img src={ChevronRight} alt="Chevron right icon" />
            </div>
          </li>
          <li
            className="card__list-item"
            onClick={() =>
              history.push("/settings/profile", {
                user_id: sessionUser.owner_id,
              })
            }
          >
            <div className="list-item__wrapper">
              <div className="list-item__title">
                <img src={AchievementIcon} alt="Achievement icon" />
                <p>Informações pessoais</p>
              </div>
              <img src={ChevronRight} alt="Chevron right icon" />
            </div>
          </li>
          <li
            className="card__list-item"
            onClick={() =>
              history.push("/settings/goal", { user_id: sessionUser.owner_id })
            }
          >
            <div className="list-item__wrapper">
              <div className="list-item__title">
                <img src={ChartIcon} alt="Chart icon" />
                <p>Seu objetivo</p>
              </div>
              <img src={ChevronRight} alt="Chevron right icon" />
            </div>
          </li>
          <li
            className="card__list-item"
            onClick={() => history.push("/settings/password")}
          >
            <div className="list-item__wrapper">
              <div className="list-item__title">
                <img src={PrivacyIcon} alt="Chart icon" />
                <p>Senha</p>
              </div>
              <img src={ChevronRight} alt="Chevron right icon" />
            </div>
          </li>
        </ul>
      </div>
      <div className="settings__card">
        <h2>Notificações</h2>
        <ul className="card__list">
          <li className="card__list-item">
            <div className="list-item__wrapper">
              <div className="list-item__title">
                <img src={BellIcon} alt="Bell icon" />
                <p>Push notification</p>
              </div>
              <Switch defaultChecked onChange={(state) => console.log(state)} />
            </div>
          </li>
        </ul>
      </div>
      <Navigation active="settings" />
    </div>
  );
}
