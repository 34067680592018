import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Hooks
import { useAuth } from "../context/auth";

// Components
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

// Assets
import EmailIcon from "../assets/icons/email-icon.svg";
import PasswordIcon from "../assets/icons/lock-icon.svg";

// Form schema
const schema = yup
  .object({
    email: yup
      .string()
      .email("Insira um email válido")
      .required("Campo email é obrigatório"),
    password: yup.string().required("Campo senha é obrigatório"),
  })
  .required();

export function Login() {
  // Hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { signIn, loading } = useAuth();

  const onSubmit = async ({ email, password }) => {
    signIn({ email, password });
  };

  return (
    <section className="login container">
      <div className="login__heading">
        <p className="heading__text">Olá,</p>
        <h1 className="heading__title">Seja bem vindo de volta</h1>
      </div>
      <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Digite seu email"
          name="email"
          type="email"
          leftElement={<img src={EmailIcon} alt="User icon" />}
          required
          isErrored={!!errors.email}
          errorMessage={errors.email?.message}
          register={register}
        />
        <Input.Password
          placeholder="Digite sua senha"
          name="password"
          leftElement={<img src={PasswordIcon} alt="User icon" />}
          required
          isErrored={!!errors.password}
          errorMessage={errors.password?.message}
          register={register}
        />
        <div className="form__submit-button">
          <Button type="submit" isLoading={loading} isFullWidth>
            Entrar
          </Button>
        </div>
      </form>
      <div className="login__divider">
        <hr />
        <span>Ou</span>
        <hr />
      </div>
      <div className="login__signup">
        <p className="signup__message">
          Ainda não possui uma conta? <Link to="/signup">Clique aqui</Link>
        </p>
      </div>
    </section>
  );
}
