import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery } from "react-query";
import { message } from "antd";
import { Player } from "video-react";

// Context
import { useAuth } from "../context/auth";

// Services
import api from "../services/api";
import { queryClient } from "../services/queryClient";

// Utils
import { shortString } from "../utils/shortString";
import { getQueryParams } from "../utils/getQueryParams";

// Components
import { Header } from "../components/ui/header";
import { Button } from "../components/forms/Button";
import { Input } from "../components/forms/Input";

// Assets
import PlayIcon from "../assets/icons/play-icon.svg";
import ScaleIcon from "../assets/icons/scale-icon.svg";

// Form schema
const schema = yup
  .object({
    weight: yup.string().required("Campo peso é obrigatório"),
  })
  .required();

export function Exercise() {
  // State
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Ref`s
  const videoRef = useRef(null);
  const videoReactRef = useRef(null);

  // Hooks
  const { user } = useAuth();
  const history = useHistory();
  const { exercise_id } = getQueryParams();

  // Cached API Call
  const { data } = useQuery(
    ["exercise", exercise_id],
    async () => {
      const { data: exercise } = await api.get(`exercises/${exercise_id}`);
      const { data: currentWeight } = await api.get(
        `clients/${user.owner_id}/exercises/${exercise_id}`
      );

      const exerciseInfos = {
        name: exercise.name,
        description: exercise.description,
        how_to: exercise.how_to,
        needs_weight: exercise.needs_weight,
        thumbnail: exercise.thumbnail,
        thumbnail_url: exercise.thumbnail_url,
        video_url: exercise.video_url,
        weight: currentWeight.weight,
      };

      setValue("weight", currentWeight?.weight);

      return exerciseInfos;
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 7 day
    }
  );

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      weight: data?.weight,
    },
  });

  useEffect(() => {
    if (window.screen.orientation)
      window.screen.orientation.lock("portrait").catch(() => {});

    if (!videoRef.current) return;

    const fullscreenEventArr = [
      "fullscreenchange",
      "webkitfullscreenchange",
      "mozfullscreenchange",
      "msfullscreenchange",
    ];

    function _onToggleFullsceen() {
      if (!isFullScreen) {
        videoRef.current.classList.remove("video--hidden");
        videoRef.current.play();
      }
      if (isFullScreen) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        videoRef.current.classList.add("video--hidden");
      }

      setIsFullScreen(!isFullScreen);
    }

    fullscreenEventArr.forEach((e) => {
      document.addEventListener(e, _onToggleFullsceen, false);
    });

    videoRef.current.addEventListener("webkitendfullscreen", () => {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.classList.add("video--hidden");
    });

    return () => {
      fullscreenEventArr.forEach((e) =>
        document.removeEventListener(e, _onToggleFullsceen, false)
      );
      if (window.screen.orientation) window.screen.orientation.unlock();
    };
  }, [isFullScreen]);

  function handlePlayVideo() {
    videoReactRef.current.toggleFullscreen();
    videoReactRef.current.play();
  }

  function handleReadMore() {
    const textElements = Array.from(
      document.getElementsByClassName("description__text")
    );

    textElements.forEach((el) => {
      if (el.classList.contains("description__text--short")) {
        el.setAttribute("style", "display: none");
      }

      if (el.classList.contains("description__text--long")) {
        el.setAttribute("style", "display: inline-block");
      }
    });
  }

  const onSubmit = async ({ weight }) => {
    setIsLoading(true);
    try {
      const { exercise_id } = getQueryParams();
      await api.patch(
        `/clients/${user.owner_id}/exercises/${exercise_id}/weight`,
        { weight }
      );
      queryClient.invalidateQueries(["exercise", exercise_id]);
      message.success("Peso alterado com sucesso!");
    } catch (error) {
      message.error(
        "Não foi possível alterar o peso. Tente novamente mais tarde!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="exercise-page container">
      <Header handleButtonClick={() => history.goBack()} />
      <div className="exercise-page__video">
        <img src={data?.thumbnail_url} alt={data?.thumbnail} />
        {data?.video_url && (
          <Player
            ref={videoReactRef}
            preload="auto"
            poster={data?.thumbnail}
            muted
            loop
          >
            <source src={data?.video_url} />
          </Player>
        )}
        <div className="video__play-button" onClick={handlePlayVideo}>
          <img src={PlayIcon} alt="Play icon" />
        </div>
      </div>
      <div className="exercise-page__exercise">
        <h1 className="exercise__title">{data?.name}</h1>
        <p className="exercise__text">Easy | 390 Calories Burn</p>
      </div>
      <div className="exercise-page__description">
        <h2 className="description__title">Descrição</h2>
        <p
          className="description__text description__text--short"
          onClick={handleReadMore}
        >
          {shortString({
            string: data?.description,
            maxLength: 195,
            endChars: " ",
          })}
          <span className="description__read-more">Leia Mais...</span>
        </p>
        <p className="description__text description__text--long">
          {data?.description}
        </p>
      </div>
      {data?.how_to && (
        <div className="exercise-page__how-to-do">
          <div className="how-to-do__title-wrapper">
            <h2 className="how-to-do__title">Como Fazer</h2>
            <p className="how-to-do__steps">4 passos</p>
          </div>
          <ul className="how-to-do__list">
            <li className="how-to-do__list-item">
              <h3 className="list-item__title">Spread Your Arms</h3>
              <p className="list-item__text">
                To make the gestures feel more relaxed, stretch your arms as you
                start this movement. No bending of hands.
              </p>
            </li>
            <li className="how-to-do__list-item">
              <h3 className="list-item__title">Rest at The Toe</h3>
              <p className="list-item__text">
                The basis of this movement is jumping. Now, what needs to be
                considered is that you have to use the tips of your feet
              </p>
            </li>
            <li className="how-to-do__list-item">
              <h3 className="list-item__title">Adjust Foot Movement</h3>
              <p className="list-item__text">
                Jumping Jack is not just an ordinary jump. But, you also have to
                pay close attention to leg movements.
              </p>
            </li>
            <li className="how-to-do__list-item">
              <h3 className="list-item__title">Clapping Both Hands</h3>
              <p className="list-item__text">
                This cannot be taken lightly. You see, without realizing it, the
                clapping of your hands helps you to keep your rhythm while doing
                the Jumping Jack
              </p>
            </li>
          </ul>
        </div>
      )}
      {data?.needs_weight && (
        <div className="exercise-page__last-weight">
          <h2 className="last-weight__title">Peso utilizado</h2>
          <form className="last-weight__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__input-box">
              <Input
                placeholder="Último peso utilizado"
                name="weight"
                inputMode="numeric"
                pattern="[0-9]*"
                type="text"
                leftElement={<img src={ScaleIcon} alt="Scale icon" />}
                required
                isErrored={!!errors.weight}
                errorMessage={errors.weight?.message}
                register={register}
              />
              <div className="box">
                <span>KG</span>
              </div>
            </div>
            <div className="form__submit-button">
              <Button type="submit" isLoading={isLoading} isFullWidth>
                Salvar
              </Button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
}
