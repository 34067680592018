import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Services
import api from "../services/api";

// Components
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

// Assets
import GoalIlustration from "../assets/images/goal-infos-ilustration.svg";
import ChartIcon from "../assets/icons/chart-icon.svg";
import LocationIcon from "../assets/icons/location-icon.svg";

// Form schema
const schema = yup
  .object({
    training_goal: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo meta é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    training_place: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo local de treino é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    training_level: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo nivel de treino é obrigatório",
        (field) => field !== "empty-disabled"
      ),
  })
  .required();

export function Goal() {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [goals, setGoals] = useState([]);
  const [places, setPlaces] = useState([]);

  // Hooks
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  // Effects
  useEffect(_getGoalsInfos, []);

  function _getGoalsInfos() {
    async function fetchData() {
      const goals = await api.get("/goals");
      const places = await api.get("/places");
      setGoals(goals.data);
      setPlaces(places.data);
    }
    fetchData();
  }

  const onSubmit = async ({
    training_goal,
    training_place,
    training_level,
  }) => {
    setIsLoading(true);
    try {
      const { data } = await api.post("/clients", {
        email: queryParams.get("email"),
        step: queryParams.get("step"),
        goal_id: training_goal,
        training_place_id: training_place,
        level: training_level,
      });

      return history.replace("/signup-success", { firstname: data.first_name });
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="signup-goal-step container">
      <div className="signup-goal-step__heading">
        <img
          className="heading__ilustration"
          src={GoalIlustration}
          alt="Goal ilustration"
        />
        <div className="heading__content">
          <h1 className="heading__title">Qual a sua meta?</h1>
          <p className="heading__text">
            Isso nos ajudará a escolher o melhor programa de treinamento para
            você!
          </p>
        </div>
      </div>
      <form
        className="signup-goal-step__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input.Select
          placeholder="Escolha sua meta"
          name="training_goal"
          leftElement={<img src={ChartIcon} alt="Chart icon" />}
          required
          isErrored={!!errors.training_goal}
          errorMessage={errors.training_goal?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          {goals?.map((goal) => (
            <option key={goal.id} value={goal.id}>
              {goal.name}
            </option>
          ))}
        </Input.Select>
        <Input.Select
          placeholder="Escolha onde treinar"
          name="training_place"
          leftElement={<img src={LocationIcon} alt="Location icon" />}
          required
          isErrored={!!errors.training_place}
          errorMessage={errors.training_place?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          {places.map((place) => (
            <option key={place.id} value={place.id}>
              {place.locale}
            </option>
          ))}
        </Input.Select>
        <Input.Select
          placeholder="Escolha seu nivel de treino"
          name="training_level"
          leftElement={<img src={LocationIcon} alt="Location icon" />}
          required
          isErrored={!!errors.training_level}
          errorMessage={errors.training_level?.message}
          register={register}
          defaultValue="empty-disabled"
        >
          <option value="iniciante">Iniciante</option>
          <option value="intermediario">Intermediário</option>
          <option value="avancado">Avançado</option>
        </Input.Select>
        <div className="form__submit-button">
          <Button type="submit" isLoading={isLoading} isFullWidth>
            Próximo
          </Button>
        </div>
      </form>
    </section>
  );
}
