import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { formatDistanceToNow, parseISO } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";
import Avatar from "react-avatar";
// import { message } from "antd";

// Context
import { useAuth } from "../context/auth";

// Services
import api from "../services/api";

// Utils
import { shortString } from "../utils/shortString";

// Components
import { Navigation } from "../components/ui/navigation";

// Assets
import LogoutIcon from "../assets/icons/logout-icon.svg";

export function Feed() {
  // State
  const [pageNumber, setPageNumber] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(null);

  // Hooks
  const { user, signOut } = useAuth();

  // Cached API call
  const { data, refetch } = useQuery(
    ["feed", pageNumber],
    async () => {
      const take = 0;

      const {
        data: { posts, total: count },
      } = await api.get(`/posts?take=${take}&skip=${pageNumber * take}`);

      setTotal(Math.ceil(count / take));
      setPageNumber(pageNumber + 1);

      return posts;
    },
    {
      keepPreviousData: true,
      enabled: false,
    }
  );

  useEffect(() => refetch(), [refetch]);

  function handleReadMore() {
    const textElements = Array.from(
      document.getElementsByClassName("footer__description")
    );

    textElements.forEach((el) => {
      if (el.classList.contains("description__text--short")) {
        el.setAttribute("style", "display: none");
      }

      if (el.classList.contains("description__text--long")) {
        el.setAttribute("style", "display: inline-block");
      }
    });
  }

  return (
    <div className="feed container">
      <header className="feed__header">
        <div className="header__content">
          <p>Bem vindo de volta,</p>
          <h2>{`${user.fisrtName.split(" ")[0]} ${user.lastName}`}</h2>
        </div>
        <button className="header__logout" onClick={signOut}>
          <img src={LogoutIcon} alt="Logout icon" />
        </button>
      </header>
      <div className="feed__content">
        {data?.map((post) => (
          <div className="feed__post" key={post.id}>
            <div className="post__header">
              <Avatar name="s t" size="55" color="#dddada" round />
              <div className="header__infos">
                <h3>Smartraining</h3>
                <p>
                  {formatDistanceToNow(parseISO(post.created_at), {
                    locale: ptBr,
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </p>
              </div>
            </div>
            <div className="post__content">
              {post.files.map((file) => (
                <div className="content__item" key={file.id}>
                  <img src={file.original} alt={file.id} />
                </div>
              ))}
            </div>
            <div className="post__footer">
              <p
                className="footer__description description__text--short"
                onClick={handleReadMore}
              >
                <span className="description__user">@smartraining </span>
                {shortString({
                  string: post.content,
                  maxLength: 195,
                  endChars: " ",
                })}
                <span className="description__read-more">Leia Mais...</span>
              </p>
              <p className="footer__description description__text--long">
                <span className="description__user">@smartraining </span>
                {post.content}
              </p>
            </div>
          </div>
        ))}
      </div>
      <Navigation active="feed" />
    </div>
  );
}
