import React from "react";
import { useQuery } from "react-query";

// Context
import { useAuth } from "../context/auth";

// Services
import api from "../services/api";

// Components
import { Navigation } from "../components/ui/navigation";
import { Button } from "../components/forms/Button";

// Assets
import LogoutIcon from "../assets/icons/logout-icon.svg";

export function Instructor() {
  // Hooks
  const { user, signOut } = useAuth();

  // Cached API calls
  const { data } = useQuery(
    "instructor",
    async () => {
      const { data } = await api.get(
        `/clients/${user?.owner_id}/instructors/active`
      );

      const instructor = {
        avatar: data.user.avatar,
        avatar_url: data.user.avatar_url,
        name: data.name,
        description: data.description,
        phone_number: data.phone_number.replace(/[^A-Z0-9]/gi, ""),
      };

      return instructor;
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  );

  function handleReadMore() {
    const textElements = Array.from(
      document.getElementsByClassName("footer__description")
    );

    textElements.forEach((el) => {
      if (el.classList.contains("description__text--short")) {
        el.setAttribute("style", "display: none");
      }

      if (el.classList.contains("description__text--long")) {
        el.setAttribute("style", "display: inline-block");
      }
    });
  }

  return (
    <div className="instructor container">
      <header className="instructor__header">
        <div className="header__content">
          <p>Bem vindo de volta,</p>
          <h2>{`${user.fisrtName.split(" ")[0]} ${user.lastName}`}</h2>
        </div>
        <button className="header__logout" onClick={signOut}>
          <img src={LogoutIcon} alt="Logout icon" />
        </button>
      </header>
      <div className="instructor__content">
        <div className="instructor__card">
          <div className="card__content">
            <div className="content__item">
              <img src={data?.avatar_url} alt={data?.avatar} />
            </div>
          </div>
          <div className="card__footer">
            <p className="footer__description" onClick={handleReadMore}>
              <span className="description__user">{`${data?.name} `}</span>
              {data?.description}
            </p>
          </div>
        </div>
        <Button
          isFullWidth
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?phone=55${
                data?.phone_number
              }&text=${`Ola treinador(a) ${data?.name}. Você pode me ajudar?`}`
            )
          }
        >
          Conversar com instrutor
        </Button>
      </div>
      <Navigation active="instructor" />
    </div>
  );
}
