export const getQueryParams = () => {
  const query = window.location.search.substring(1);
  const queryArr = query.split("&");
  let mappedQuery = {};
  queryArr.forEach((item) => {
    const splittedItem = item.split("=");

    mappedQuery = { ...mappedQuery, [splittedItem[0]]: splittedItem[1] };
  });

  return mappedQuery;
};
