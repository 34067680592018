import React from "react";
import { Route, Redirect } from "react-router-dom";

// Context
import { useAuth } from "../context/auth";

const Public = ({ component: Component, restricted, ...rest }) => {
  // Auth Hook
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? <Redirect to="/feed" /> : <Component {...props} />
      }
    />
  );
};

export default Public;
