import { useHistory } from "react-router-dom";

// Components
import { Button } from "../components/forms/Button";

// Assets
import SuccessIlustration from "../assets/images/login-success-ilustration.svg";

export function Success({ location }) {
  // Hooks
  const history = useHistory();

  return (
    <section className="success container">
      <div className="success__content">
        <div className="ilustration">
          <img
            className="ilustration__image"
            src={SuccessIlustration}
            alt="Success Ilustration"
          />
        </div>
        <div className="welcome-message">
          <h1 className="welcome-message__title">
            Bem vindo, {location?.state?.firstname}
          </h1>
          <p className="welcome-message__text">
            Está tudo pronto, vamos consquistar seus objetivos juntos!
          </p>
        </div>
      </div>
      <div className="success__button">
        <Button type="button" isFullWidth onClick={() => history.replace("/")}>
          Fazer login
        </Button>
      </div>
    </section>
  );
}
