import React from "react";
import { Route, Redirect } from "react-router-dom";

// Context
import { useAuth } from "../context/auth";

const Private = ({ component: Component, ...rest }) => {
  // Auth Hook
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default Private;
