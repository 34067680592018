import { useQuery } from "react-query";
import Avatar from "react-avatar";
import { formatDistanceToNow, parseISO } from "date-fns";
import ptBr from "date-fns/locale/pt-BR";

// Context
import { useAuth } from "../context/auth";

// Services
import api from "../services/api";

// Components
import { Navigation } from "../components/ui/navigation";
import { Button } from "../components/forms/Button";

// Assets
import LogoutIcon from "../assets/icons/logout-icon.svg";
import JumpRopeIlustration from "../assets/icons/ilustrations/jump-rope.svg";
import WeightliftingIlustration from "../assets/icons/ilustrations/weightlifting.svg";
import AbsIlustration from "../assets/icons/ilustrations/abs.svg";
import { useHistory } from "react-router-dom";

export const Statistics = () => {
  // Hooks
  const { user, signOut } = useAuth();
  const history = useHistory();

  // Cached API Call
  const { data } = useQuery(
    ["statistics"],
    async () => {
      const { data: activeTraining } = await api.get(
        `clients/${user.owner_id}/trainings/active`
      );
      const { data: trainingReviews } = await api.get(
        `clients/${user.owner_id}/phases/reviews`
      );
      console.log({ trainingReviews });

      const trainings = {
        training_name: activeTraining.name,
        phases: activeTraining.phases,
      };

      return { trainings, reviews: trainingReviews };
    },
    {
      staleTime: 1000 * 60 * 60 * 24 * 7, // 7 day
    }
  );

  // Functions
  const handleSelectIlustration = (index) => {
    if (index + 1 === 1) return JumpRopeIlustration;

    if ((index + 1) % 2 === 0) return WeightliftingIlustration;

    return AbsIlustration;
  };

  return (
    <div className="statistics container">
      <header className="feed__header">
        <div className="header__content">
          <p>Bem vindo de volta,</p>
          <h2>{`${user.fisrtName.split(" ")[0]} ${user.lastName}`}</h2>
        </div>
        <button className="header__logout" onClick={signOut}>
          <img src={LogoutIcon} alt="Logout icon" />
        </button>
      </header>
      <div className="statistics__wrapper">
        <h2 className="statistics__title">Treino ativo</h2>
        <div className="statistics__content">
          {data?.trainings?.phases.map((training, index) => (
            <div className="content__training-card" key={training.id}>
              <img src={handleSelectIlustration(index)} alt="Ilustration" />
              <div className="training-card__info">
                <h3>
                  {data?.trainings.training_name} <span>{training.name}</span>
                </h3>
                <Button
                  type="text"
                  size="sm"
                  onClick={() =>
                    history.push(`training`, { training, readOnly: true })
                  }
                >
                  Ver
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="statistics__wrapper">
        <h2 className="statistics__title">Últimos treinos</h2>
        <div className="statistics__reviews">
          {data?.reviews.map((review) => (
            <div className="content__card" key={review.datetime_end}>
              <Avatar
                name={review.training_name}
                size="50"
                color="#dddada"
                round
              />
              <div className="card__info">
                <h3>
                  {review.training_name} <span>{review.phase_name}</span>
                </h3>
                <p>
                  {formatDistanceToNow(parseISO(review.datetime_end), {
                    locale: ptBr,
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Navigation active="statistics" />
    </div>
  );
};
