// Components
import classNames from "classnames";

// Utils
import { stringToSlug } from "../../../utils/stringToSlug";

// Components
import { Base } from "./Base";

// Assets
import DropdownIcon from "../../../assets/icons/dropdown-icon.svg";

export function Select({
  label,
  name,
  placeholder,
  leftElement,
  register,
  required,
  isErrored,
  errorMessage,
  children,
  ...props
}) {
  // Classnames
  const erroredClassNames = classNames({
    "--invalid": isErrored,
  });

  return (
    <Base
      slug={stringToSlug(label)}
      label={label}
      isRequired={required}
      isErrored={isErrored}
      errorMessage={errorMessage}
    >
      <div
        className={`input__container input${erroredClassNames}`}
        tabIndex={0}
      >
        {!!leftElement && (
          <div className="input__icon icon--left">{leftElement}</div>
        )}
        <select
          className="select__element"
          required={required}
          {...register(name, { required })}
          {...props}
        >
          {placeholder && (
            <option value="empty-disabled" disabled>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        <div className="input__icon icon--right">
          <img src={DropdownIcon} alt="Dropdown Icon" />
        </div>
      </div>
    </Base>
  );
}
