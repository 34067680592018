import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { format, formatISO } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Context
import { useAuth } from "../context/auth";

// Services
import api from "../services/api";
import { queryClient } from "../services/queryClient";

// Utils
import { getQueryParams } from "../utils/getQueryParams";

// Components
import { Header } from "../components/ui/header";
import { Rate } from "../components/forms/Rate";
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

// Assets
import CalendarIcon from "../assets/icons/calendar-icon.svg";
import { message } from "antd";

// Form schema
const schema = yup
  .object({
    rating: yup.string(),
    borg_scale: yup
      .string()
      .test(
        "is-gender-empty-test",
        "Campo dificuldade é obrigatório",
        (field) => field !== "empty-disabled"
      ),
    observation: yup.string(),
  })
  .required();

export function SubmitTraining() {
  // State
  const [loading, setLoading] = useState(false);

  // Hooks
  const history = useHistory();
  const { user } = useAuth();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const { training_id } = getQueryParams();
      await api.post(
        `/clients/${user.owner_id}/phases/${training_id}/reviews`,
        {
          ...data,
          datetime_end: formatISO(new Date()),
        }
      );
      queryClient.invalidateQueries("training");
      queryClient.invalidateQueries("statistics");
      history.replace("/finished-training");
    } catch (error) {
      message.error(
        "Não foi possível finalizar o treino. Tente novamente mais tarde!"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="submit-training container">
      <Header
        title="Finalizar Treino"
        handleButtonClick={() => history.goBack()}
      />
      <div className="submit-training__card">
        <div className="card__bg" />
        <div className="card__icon">
          <img src={CalendarIcon} alt="Calendar icon" />
          <p className="card__text">Finalizado em</p>
        </div>
        <p className="card__text">{format(new Date(), "dd/MM, HH:mm")}</p>
      </div>
      <div className="submit-training__details">
        <form className="details__form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="rating"
            control={control}
            render={({ field }) => (
              <Rate label="Avalie seu treino" defaultValue={0} {...field} />
            )}
          />
          <Input.Select
            label="Dificuldade"
            placeholder="Selecione a dificuldade do treino"
            name="borg_scale"
            required
            isErrored={!!errors.borg_scale}
            errorMessage={errors.borg_scale?.message}
            register={register}
            defaultValue="empty-disabled"
          >
            <option value="1">Atividade muito leve</option>
            <option value="2">Atividade leve</option>
            <option value="3">Atividade moderada</option>
            <option value="4">Atividade difícil</option>
            <option value="5">Atividade muito difícil</option>
            <option value="5">Atividade de máximo esforço</option>
          </Input.Select>
          <Input.Textarea
            label="Observações"
            placeholder="Digite alguma informação sobre a realização do treino..."
            name="observation"
            rows={4}
            isErrored={!!errors.observation}
            errorMessage={errors.observation?.message}
            register={register}
          />
          <div className="form__submit-button">
            <Button type="submit" isLoading={loading} isFullWidth>
              Enviar treino
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}
