import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { message } from "antd";

// Services
import api from "../services/api";

// Components
import { Input } from "../components/forms/Input";
import { Button } from "../components/forms/Button";

// Assets
import UserIcon from "../assets/icons/user-icon.svg";
import EmailIcon from "../assets/icons/email-icon.svg";
import PasswordIcon from "../assets/icons/lock-icon.svg";

// Form schema
const schema = yup
  .object({
    firstname: yup.string().required("Campo nome é obrigatório"),
    lastname: yup.string().required("Campo sobrenome é obrigatório"),
    email: yup.string().required("Campo email é obrigatório"),
    password: yup.string().required("Campo senha é obrigatório"),
  })
  .required();

export function Account() {
  // State
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const onSubmit = async ({ firstname, lastname, email, password }) => {
    setIsLoading(true);

    try {
      const { status, data } = await api.post("/clients", {
        firstName: firstname,
        lastName: lastname,
        email,
        password,
      });

      if (status === 201) {
        return history.push(`/signup-profile?email=${email}&step=2`);
      }

      switch (data.signin_step) {
        case 1:
          return history.push(`/signup-profile?email=${email}&step=2`);
        case 2:
          return history.push(`/signup-goal?email=${email}&step=3`);
        case 3:
          message.success("Usuário já cadastrado! Por favor, faça o login.");
          return history.push("/");

        default:
          break;
      }
    } catch (error) {
      return message.error(
        "Ops, algo de errado aconteceu. Tente novamente mais tarde!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account container">
      <div className="account__heading">
        <p className="heading__text">Olá,</p>
        <h1 className="heading__title">Crie sua conta</h1>
      </div>
      <form className="account__form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Digite seu nome"
          name="firstname"
          type="text"
          leftElement={<img src={UserIcon} alt="User icon" />}
          required
          isErrored={!!errors.firstname}
          errorMessage={errors.firstname?.message}
          register={register}
        />
        <Input
          placeholder="Digite seu sobrenome"
          name="lastname"
          type="text"
          leftElement={<img src={UserIcon} alt="User icon" />}
          required
          isErrored={!!errors.lastname}
          errorMessage={errors.lastname?.message}
          register={register}
        />
        <Input
          placeholder="Digite seu email"
          name="email"
          type="email"
          leftElement={<img src={EmailIcon} alt="User icon" />}
          required
          isErrored={!!errors.email}
          errorMessage={errors.email?.message}
          register={register}
        />
        <Input.Password
          placeholder="Digite sua senha"
          name="password"
          leftElement={<img src={PasswordIcon} alt="User icon" />}
          required
          isErrored={!!errors.password}
          errorMessage={errors.password?.message}
          register={register}
        />
        <p className="form__terms">
          Ao continuar você aceita nossas{" "}
          <a href="/">Políticas de Privacidade</a> e{" "}
          <a href="/">Termos de Uso</a>
        </p>
        <div className="form__submit-button">
          <Button type="submit" isFullWidth isLoading={isLoading}>
            Registrar
          </Button>
        </div>
      </form>
      <div className="account__divider">
        <hr />
        <span>Ou</span>
        <hr />
      </div>
      <div className="account__login">
        <p className="login__message">
          Já possui uma conta? <Link to="/">Fazer login</Link>
        </p>
      </div>
    </section>
  );
}
