import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation } from "react-query";
import { message } from "antd";

// Services
import api from "../../services/api";
import { queryClient } from "../../services/queryClient";

// Components
import { Header } from "../../components/ui/header";
import { Input } from "../../components/forms/Input";
import { Button } from "../../components/forms/Button";
import { Navigation } from "../../components/ui/navigation";

// Assets
import ArrowLeft from "../../assets/icons/arrow-left-icon.svg";
import UserIcon from "../../assets/icons/user-icon.svg";
import EmailIcon from "../../assets/icons/email-icon.svg";

// Form schema
const schema = yup
  .object({
    firstname: yup.string().required("Campo nome é obrigatório"),
    lastname: yup.string().required("Campo sobrenome é obrigatório"),
  })
  .required();

export function EditAccount({ location }) {
  const { user_id } = location.state;

  const { data } = useQuery(
    ["user_account"],
    async () => {
      const { data } = await api.get(`/clients/${user_id}`);

      const accountInfos = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.user.email,
      };

      setValue("firstname", data.first_name);
      setValue("lastname", data.last_name);
      setValue("email", data.user.email);

      return accountInfos;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day
    }
  );

  const updateUser = useMutation(
    async ({ firstname, lastname }) => {
      await api.put(`/clients/${user_id}`, {
        first_name: firstname,
        last_name: lastname,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
        queryClient.invalidateQueries("user_account");
        message.success("Dados alterados com sucesso!");
        history.goBack();
      },
      onError: () =>
        message.error(
          "Ops, algo de errado aconteceu. Tente novamente mais tarde!"
        ),
    }
  );

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: data?.first_name,
      lastname: data?.last_name,
      email: data?.email,
    },
  });
  const history = useHistory();

  const onSubmit = async (data) => updateUser.mutateAsync(data);

  return (
    <section className="account container">
      <Header
        icon={ArrowLeft}
        title="Informações da conta"
        handleButtonClick={() => history.goBack()}
      />
      <form className="account__form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder="Digite seu nome"
          name="firstname"
          type="text"
          leftElement={<img src={UserIcon} alt="User icon" />}
          required
          isErrored={!!errors.firstname}
          errorMessage={errors.firstname?.message}
          register={register}
        />
        <Input
          placeholder="Digite seu sobrenome"
          name="lastname"
          type="text"
          leftElement={<img src={UserIcon} alt="User icon" />}
          required
          isErrored={!!errors.lastname}
          errorMessage={errors.lastname?.message}
          register={register}
        />
        <Input
          placeholder="Digite seu email"
          name="email"
          type="email"
          leftElement={<img src={EmailIcon} alt="User icon" />}
          disabled
          isErrored={!!errors.email}
          errorMessage={errors.email?.message}
          register={register}
        />
        <div className="form__submit-button">
          <Button type="submit" isFullWidth isLoading={updateUser.isLoading}>
            Editar
          </Button>
        </div>
      </form>
      <Navigation active="settings" />
    </section>
  );
}
